import React, { useState, useEffect, useRef,useCallback } from 'react';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import { v4 as uuidv4 } from 'uuid';
import { X } from 'lucide-react';

interface Message {
  content: string;
  isUser: boolean;
  isAudio?: boolean;
}

interface ChatInterfaceProps {
  logoContainerRef: any;
}

// Add these outside the component
interface AudioQueueItem {
  audioUrl: string;
  blob: Blob;
}

const base64ToUint8Array = (base64: string): Uint8Array => {
  try {
    // Replace URL-safe characters back to standard base64
    const standardBase64 = base64
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    // Add padding if needed
    const paddedBase64 = standardBase64.padEnd(
      standardBase64.length + (4 - (standardBase64.length % 4)) % 4,
      '='
    );

    const binaryString = window.atob(paddedBase64);
    const bytes = new Uint8Array(binaryString.length);
    
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    
    return bytes;
  } catch (error) {
    console.error('Error converting base64 to Uint8Array:', error);
    throw error;
  }
};

const concatenateUint8Arrays = (arrays: Uint8Array[]): Uint8Array => {
  const totalLength = arrays.reduce((acc, arr) => acc + arr.length, 0);
  const result = new Uint8Array(totalLength);
  let offset = 0;
  
  for (const arr of arrays) {
    result.set(arr, offset);
    offset += arr.length;
  }
  
  return result;
};

const ChatInterface: React.FC<ChatInterfaceProps> = ({ logoContainerRef }) => {
  console.log("  ChatInterface loaded")
  const [messages, setMessages] = useState<Message[]>([]);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const ChatInputRef = useRef<HTMLDivElement>(null);
 
  const [uuid, setUuid] = useState('');
  const [chatHeight, setChatHeight] = useState('80vh'); 
  const [isReceivingResponse, setIsReceivingResponse] = useState(false);

  const abortControllerRef = useRef<AbortController | null>(null);
  // Add these new state/ref variables
  const audioQueue = useRef<AudioQueueItem[]>([]);
  const isAudioPlaying = useRef<boolean>(false);
  const currentAudio = useRef<HTMLAudioElement | null>(null);

  // Add this state
  const [isAudioPlayingState, setIsAudioPlayingState] = useState(false);

  //   // Function to play the next audio in queue
  const playNextInQueue = useCallback(() => {
    if (audioQueue.current.length === 0) {
      isAudioPlaying.current = false;
      setIsAudioPlayingState(false);
      return;
    }

    isAudioPlaying.current = true;
    setIsAudioPlayingState(true);
    const nextItem = audioQueue.current.shift();
    
    if (nextItem) {
      const audio = new Audio(nextItem.audioUrl);
      currentAudio.current = audio;
      
      // When this audio finishes, clean up and play the next one
      audio.onended = () => {
        URL.revokeObjectURL(nextItem.audioUrl);
        currentAudio.current = null;
        playNextInQueue();
      };
      
      // Handle errors
      audio.onerror = () => {
        console.error('Error playing audio');
        URL.revokeObjectURL(nextItem.audioUrl);
        currentAudio.current = null;
        playNextInQueue();
      };
      
      // Start playing
      audio.play().catch(err => {
        console.error('Failed to play audio:', err);
        playNextInQueue();
      });
    }
  }, []);


    // Function to add audio to the queue and start playing if needed
  const enqueueAudio = useCallback((blob: Blob) => {
    const audioUrl = URL.createObjectURL(blob);
    audioQueue.current.push({ audioUrl, blob });
    
    // If nothing is currently playing, start the queue
    if (!isAudioPlaying.current) {
      setIsAudioPlayingState(true);
      playNextInQueue();
    }
  }, [playNextInQueue]);

  useEffect(() => {
    const calculateChatHeight = () => {

        const headerHeight = logoContainerRef.current ? logoContainerRef.current.offsetHeight : 0;
        const footerHeight = ChatInputRef.current ? ChatInputRef.current.offsetHeight : 0;
        const remainingHeight = `calc(100vh - ${(window.innerWidth < 640 ? headerHeight : 0) + footerHeight}px)`;
        setChatHeight(remainingHeight);
      
    };

    // Calculate height on mount and on resize
    calculateChatHeight();
    window.addEventListener('resize', calculateChatHeight);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', calculateChatHeight);
    };
  }, []);



// const handleSendMessage = useCallback(
//   async (content: string, isAudio?: boolean) => {
//     const userMessage: Message = { content, isUser: true, isAudio: isAudio || false };
//     setMessages((prevMessages) => [...prevMessages, userMessage]);

//     try {
//       setIsReceivingResponse(true);

//       const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/chat`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'text/event-stream',
//         },
//         body: JSON.stringify({ message: content, chat_id: uuid, isAudio: isAudio || false }),
//       });

//       if (!response.body) {
//         throw new Error("Readable stream not supported.");
//       }

//       const reader = response.body.getReader();
//       const decoder = new TextDecoder();
//       let buffer = ''; // Buffer to accumulate incomplete chunks
//       let audioChunks: Uint8Array[] = [];

//       while (true) {
//         const { done, value } = await reader.read();
//         if (done) break;
        
//         // Append new chunk to existing buffer
//         buffer += decoder.decode(value, { stream: true });
        
//         // Process complete messages
//         const messages = extractCompleteMessages(buffer);
//         buffer = messages.remainder; // Keep the incomplete part for next iteration
        
//         for (const message of messages.complete) {
//           try {
//             const parsed = JSON.parse(message);
            
//             if (parsed.status === 'audio_chunk') {
//               try {
//                 const audioData = base64ToUint8Array(parsed.audio_data);
//                 audioChunks.push(audioData);
      
//                 if (parsed.is_last) {
//                   const completeAudioData = concatenateUint8Arrays(audioChunks);
//                   const blob = new Blob([completeAudioData], { type: 'audio/mp3' });
//                   const audioUrl = URL.createObjectURL(blob);
                  
//                   const audio = new Audio(audioUrl);
//                   audio.play();
                  
//                   audioChunks = [];
//                   audio.onended = () => URL.revokeObjectURL(audioUrl);
//                 }
//               } catch (audioError) {
//                 console.error('Error processing audio chunk:', audioError);
//               }
//             } else if (parsed.status === 'error') {
//               console.error('Error from backend:', parsed.content);
//               setMessages(prev => [...prev, { 
//                 content: parsed.content, 
//                 isUser: false, 
//                 isAudio: false
//               }]);
//             } else if (parsed.content) {
//               setMessages(prev => [...prev, { 
//                 content: parsed.content, 
//                 isUser: false, 
//                 isAudio: false
//               }]);
//             }
//           } catch (error) {
//             console.error('Failed to parse message:', error);
//             console.log('Problematic message:', message);
//           }
//         }
//       }
      
//       reader.releaseLock();
//       setIsReceivingResponse(false);
//     } catch (error) {
//       console.error('Error:', error);
//       setIsReceivingResponse(false);
//     }
//   },
//   [uuid]
// );

// Helper function to extract complete messages from buffer

  const handleSendMessage = useCallback(
    async (content: string, isAudio?: boolean) => {
      const userMessage: Message = { content, isUser: true, isAudio: true };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        const controller = new AbortController();
        abortControllerRef.current = controller;

        setIsReceivingResponse(true);

        const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
          },
          body: JSON.stringify({ message: content, chat_id: uuid, isAudio: isAudio || false }),
          signal: controller.signal
        });

        if (!response.body) {
          throw new Error("Readable stream not supported.");
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = ''; 

        while (true) {
          if (controller.signal.aborted) {
            console.log('Request was aborted');
            break;
          }

          const { done, value } = await reader.read();
          if (done) break;
          
          buffer += decoder.decode(value, { stream: true });
          
          const messages = extractCompleteMessages(buffer);
          buffer = messages.remainder;
          
          for (const message of messages.complete) {
            try {
              const parsed = JSON.parse(message);
              
              if (parsed.status === 'audio_complete') {
                try {
                  const audioData = base64ToUint8Array(parsed.audio_data);
                  const blob = new Blob([audioData], { type: 'audio/mp3' });
                  
                  // Add to audio queue
                  enqueueAudio(blob);
                  
                } catch (audioError) {
                  console.error('Error processing audio:', audioError);
                }
              } else if (parsed.status === 'error') {
                console.error('Error from backend:', parsed.content);
                setMessages(prev => [...prev, { 
                  content: parsed.content, 
                  isUser: false, 
                  isAudio: false
                }]);
              } else if (parsed.content) {
                setMessages(prev => [...prev, { 
                  content: parsed.content, 
                  isUser: false, 
                  isAudio: false
                }]);
              }
            } catch (error) {
              console.error('Failed to parse message:', error);
              console.log('Problematic message:', message);
            }
          }
        }
        
        reader.releaseLock();
        setIsReceivingResponse(false);
      } catch (error: any) {
        if (error?.name === 'AbortError') {
          console.log('Request was cancelled');
        } else {
          console.error('Error:', error);
        }
      } finally {
        setIsReceivingResponse(false);
        if (abortControllerRef.current?.signal.aborted) {
          abortControllerRef.current = null;
        }
      }
    },
    [uuid, enqueueAudio]
  );



const extractCompleteMessages = (buffer: string) => {
  const regex = /data: ({.*?})\n\n/gs;
  const matches = [...buffer.matchAll(regex)];
  const completeMessages = matches.map(match => match[1]);
  
  // Calculate where the last complete message ends
  let lastIndex = 0;
  if (matches.length > 0) {
    const lastMatch = matches[matches.length - 1];
    lastIndex = lastMatch.index! + lastMatch[0].length;
  }
  
  // Return complete messages and the remainder
  return {
    complete: completeMessages,
    remainder: buffer.slice(lastIndex)
  };
};

  // Clean up audio on unmount
  useEffect(() => {
    return () => {
      // Clean up any playing audio
      if (currentAudio.current) {
        currentAudio.current.pause();
        currentAudio.current = null;
      }
      
      // Clean up any remaining items in the queue
      audioQueue.current.forEach(item => {
        URL.revokeObjectURL(item.audioUrl);
      });
      audioQueue.current = [];
    };
  }, []);

const WelcomeMessage = () => {
  const messages = [
    "Hello! I am Solar Genie, your solar expert",
    "How can I help you ?"
  ];
  const [displayedMessages, setDisplayedMessages] = useState<string[]>(['', '', '']);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);


  useEffect(() => {
    if (currentMessageIndex >= messages.length) return;

    const interval = setInterval(() => {
      if (charIndex < messages[currentMessageIndex].length) {
        setDisplayedMessages(prev => {
          const newMessages = [...prev];
          newMessages[currentMessageIndex] = messages[currentMessageIndex].slice(0, charIndex + 1);
          return newMessages;
        });
        setCharIndex(prev => prev + 1);
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setCurrentMessageIndex(prev => prev + 1);
          setCharIndex(0);
        }, 500);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [currentMessageIndex, charIndex]);

  return (
    <div className="text-center h-32"> {/* Fixed height container */}
      <h1 className="sm:text-4xl text-2xl font-semibold text-black mb-4">
        {displayedMessages[0]}
      </h1>
      <p className="sm:text-4xl text-2xl font-semibold text-orange-400 h-16"> {/* Fixed height for second message */}
        {displayedMessages[1]}
      </p>
    </div>
  );
};


  // Auto-scroll to the bottom whenever messages change
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    const newUuid = uuidv4();
    console.log("##uuid 1",newUuid)
    setUuid(newUuid);
  }, []);


 const WelcomeComponent = 
 <div className="w-full h-full flex flex-col">
   <div className="h-full flex flex-col items-center sm:justify-center space-y-8 mt-4">
     <WelcomeMessage />
     <div className="">
       <ChatInput onSendMessage={handleSendMessage} disabled={isReceivingResponse} isAudioPlaying={isAudioPlayingState} />
     </div>
   </div>
</div>


  // Add this function to stop generation and audio
  const stopGeneration = useCallback(() => {
    
        // 7. Abort the current request if it exists
    if(abortControllerRef.current) {
          abortControllerRef.current.abort();
          abortControllerRef.current = null;
    }
    
    // Stop any playing audio
    if (currentAudio.current) {
      currentAudio.current.pause();
      currentAudio.current = null;
    }
    
    // Clear audio queue
    audioQueue.current.forEach(item => {
      URL.revokeObjectURL(item.audioUrl);
    });
    audioQueue.current = [];
    
    // Reset states
    setIsReceivingResponse(false);
    setIsAudioPlayingState(false);
    isAudioPlaying.current = false;
  }, []);

  return (
    <>
      {messages.length === 0 ? 
      (
        WelcomeComponent
      ) 
      : 
      (
        <div className=" w-full flex flex-col"
        style={{height: `calc(100vh - ${window.innerWidth < 640 ? logoContainerRef?.current?.clientHeight : 0}px)`}}>
          <div className='overflow-auto' style={{height:chatHeight}}>
            <ChatHistory messages={messages} />
            <div ref={chatEndRef} /> {/* Ensures auto-scroll targets this div */}
          </div>
          <div className='h-fit sm:flex items-end' 
          ref={ChatInputRef}>
            <ChatInput 
              onSendMessage={handleSendMessage} 
              isChatStarted={messages?.length !== 0} 
              disabled={isReceivingResponse}
              isAudioPlaying={isAudioPlayingState}
              onStopGeneration={stopGeneration}
              isGenerating={isReceivingResponse || isAudioPlayingState}
            />
          </div>
    
        </div>
      )
      }
    </>
  );



};

export default ChatInterface;
