import React, { useState, useRef, useEffect } from 'react';
import { Paperclip, Send,X, Mic, StopCircle, Volume2, VolumeX } from 'lucide-react';
import typingIcon from '../images/typing.gif';

// Add this type declaration at the top of the file
declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

interface ChatInputProps {
  onSendMessage: (message: string,isAudio?: boolean) => void;
  disabled?: boolean;
  isChatStarted?: boolean;
  isAudioPlaying?: boolean;
  onStopGeneration?: () => void;
  isGenerating?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, disabled, isChatStarted, isAudioPlaying, onStopGeneration, isGenerating }) => {
  const [input, setInput] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [error, setError] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const recognitionRef = useRef<any>(null);

  // console.log("disabled", disabled  );

  
  // Add suggested questions
  const suggestedQuestions = [
    "I want to install solar",
    "What is the process to get started?",
    "How much can I save with solar?",
    "What plant capacity do I need?",
    "Do you have customer testimonials?",
    // "What is the cost of installing a solar plant"
  ];

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  const uploadImage = async (file: File) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/uploadfile`, {
        method: 'POST',
        headers: {
          'accept': 'application/json, text/plain, */*',
          'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (input.length > 500) {
      setError('Message cannot exceed 500 characters');
      return;
    }
    if ((input.trim() || selectedFile) && !disabled && !isUploading) {
      try {
        let finalMessage = input.trim();
        
        if (selectedFile) {
          const imageUrl = await uploadImage(selectedFile);
          finalMessage = finalMessage ? `${finalMessage} ${imageUrl}` : imageUrl;
          setSelectedFile(null);
        }

        onSendMessage(finalMessage, isAudioEnabled);
        setInput('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    // console.log(file?.size);
    if (file) {
      if (file.size > 10 * 1024 * 1024) { // 10MB in bytes
        alert('File size must be less than 10 MB');
        e.target.value = '';
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleSuggestedQuestionClick = (question: string) => {
    setInput(question);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInput(newValue);
    setError(newValue.length > 500 ? 'Message cannot exceed 500 characters' : null);
    adjustTextareaHeight();
  };

  //using backedn api to record audio
  // const startRecording = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     const mediaRecorder = new MediaRecorder(stream);
  //     mediaRecorderRef.current = mediaRecorder;
  //     chunksRef.current = [];

  //     mediaRecorder.ondataavailable = (e) => {
  //       chunksRef.current.push(e.data);
  //     };

  //     mediaRecorder.start();
  //     setIsRecording(true);
  //   } catch (err) {
  //     console.error('Error accessing microphone:', err);
  //     alert('Error accessing microphone. Please check permissions.');
  //   }
  // };

  // const stopRecording = async () => {
  //   if (mediaRecorderRef.current && isRecording) {
  //     mediaRecorderRef.current.stop();
  //     setIsRecording(false);

  //     mediaRecorderRef.current.onstop = async () => {
  //       const audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
  //       const formData = new FormData();
  //       formData.append('audio', audioBlob);

  //       try {
  //         const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/transcribe`, {
  //           method: 'POST',
  //           body: formData,
  //         });

  //         const data = await response.json();
  //         if (data.text) {
  //           setInput(data.text);
  //           // Automatically submit the transcribed text
  //           setTimeout(() => {
  //             onSendMessage(data.text,true);
  //             setInput('');
  //           }, 0);

  //           // handleSubmit({ preventDefault: () => {} } as React.FormEvent);
  //         }
  //       } catch (error) {
  //         console.error('Error transcribing audio:', error);
  //       }

  //       // Stop all tracks on the stream
  //       mediaRecorderRef.current?.stream.getTracks().forEach(track => track.stop());
  //     };
  //   }
  // };

  // useEffect(() => {
  //   const welcomeUserMessage = 'Hi'
  //   onSendMessage(welcomeUserMessage);
  // }, []);


  const toggleRecording = async () => {
    if (isRecording) {
      // Stop recording
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
        setIsRecording(false);
      }
    } else {
      // Start recording
      try {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        
        recognition.lang = 'en-US';
        recognition.continuous = true;  // Keep recording
        recognition.interimResults = false;
    
        recognition.onresult = (event: any) => {
          const transcript = event.results[event.results.length - 1][0].transcript;
          setInput(transcript);
          onSendMessage(transcript, isAudioEnabled);
          setInput('');
        };
    
        recognition.onerror = (event: any) => {
          console.error('Speech recognition error:', event.error);
          alert('Error in speech recognition. Please try again.');
          setIsRecording(false);
          recognitionRef.current = null;
        };
    
        recognition.onend = () => {
          // If we're still supposed to be recording, restart it
          if (isRecording && recognitionRef.current) {
            recognition.start();
          } else {
            setIsRecording(false);
            recognitionRef.current = null;
          }
        };
    
        recognitionRef.current = recognition;
        recognition.start();
        setIsRecording(true);
      } catch (err) {
        console.error('Error accessing speech recognition:', err);
        alert('Speech recognition is not supported in this browser.');
        setIsRecording(false);
        recognitionRef.current = null;
      }
    }
  };

  return (
    <div className="w-full">
      <div className="mx-auto max-w-3xl sm:px-6 pb-2">
        {/* Suggested Questions */}
        <div className="mb-4 flex flex-wrap sm:gap-1 gap-1.5 justify-center">
          {!isChatStarted && suggestedQuestions.map((question, index) => (
            <button
              key={index}
              onClick={() => handleSuggestedQuestionClick(question)}
              disabled={disabled}
              className={`sm:px-3 sm:py-1.5 px-2.5 py-1 bg-gray-800 text-gray-300 rounded-full text-[0.6rem] sm:text-sm transition-colors ${
                disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
              }`}
            >
              {question}
            </button>
          ))}
        </div>

        {/* {selectedFile && (
          <div className="mt-2 text-blue-500 mb-2 text-sm flex items-center gap-2">
            <Paperclip className="w-4 h-4" />
            <span>{selectedFile.name}</span>
          </div>
        )} */}

        {selectedFile && (
          <div className="mt-2 text-blue-500 mb-2 text-sm flex items-center gap-2">
            <Paperclip className="w-4 h-4" />
            <span>{selectedFile.name}</span>
            <button
              type="button"
              onClick={() => {
                setSelectedFile(null);
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}
              className="p-0.5 hover:bg-gray-700 rounded-full transition-colors"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        )}
        { disabled && <img src={typingIcon} alt="typing" className="w-14 h-14 ml-6" />}
        <form onSubmit={handleSubmit} className="relative flex items-end bg-gray-700 rounded-xl shadow-xl">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*,application/pdf"
            disabled={disabled || isUploading}
            className="hidden"
          />
          
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={disabled || isUploading}
            className="absolute bottom-3 left-3 p-1 text-gray-400 hover:text-gray-300 transition-colors disabled:text-gray-600 disabled:cursor-not-allowed"
          >
            <Paperclip className="w-5 h-5" />
          </button>

          {isAudioPlaying || isRecording ? (
            <div className="w-full flex items-center justify-center py-3 px-12">
              <div className="flex items-center space-x-1">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className={`w-1 bg-blue-500 rounded-full transform transition-all duration-75 ease-in-out
                      ${isRecording ? 'bg-red-500' : 'bg-blue-500'}
                    `}
                    style={{
                      height: `${Math.random() * 24 + 8}px`,
                      animation: `audioWave 0.5s ease infinite ${index * 0.1}s`
                    }}
                  />
                ))}
              </div>
              <span className="ml-3 text-gray-300 text-sm">
                {isRecording ? 'Recording...' : 'Playing response...'}
              </span>
            </div>
          ) : (
            <textarea
              ref={textareaRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              placeholder="Message Solar Genie"
              rows={1}
              className={`w-full bg-transparent text-white placeholder-gray-400 pl-12 pr-12 py-3 resize-none overflow-hidden focus:outline-none focus:ring-0 border-0 ${
                error ? 'border-red-500' : ''
              } ${disabled ? 'cursor-not-allowed' : ''}`}
              style={{ maxHeight: '200px' }}
            />
          )}

          {error && (
            <div className="absolute -top-6 left-0 text-red-500 text-sm">
              {error}
            </div>
          )}

          {/* Add Speaker Toggle Button - position it before the mic button */}
          <button
            type="button"
            onClick={() => setIsAudioEnabled(!isAudioEnabled)}
            className={`absolute bottom-3 right-20 p-1 transition-colors
              ${isAudioEnabled ? 'text-blue-500 hover:text-blue-400' : 'text-gray-400 hover:text-gray-300'}
            `}
            title={isAudioEnabled ? "Disable audio responses" : "Enable audio responses"}
          >
            {isAudioEnabled ? <Volume2 className="w-5 h-5" /> : <VolumeX className="w-5 h-5" />}
          </button>

          {/* Existing Mic/Stop Button */}
          {isGenerating ? (
            <button
              type="button"
              onClick={onStopGeneration}
              className="absolute bottom-3 right-12 p-1 text-red-500 hover:text-red-400 transition-colors"
            >
              <StopCircle className="w-5 h-5" />
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleRecording}
              disabled={disabled || isUploading || isAudioPlaying}
              className={`absolute bottom-3 right-12 p-1 transition-colors ${
                isRecording ? 'text-red-500' : 'text-gray-400 hover:text-gray-300'
              } disabled:text-gray-600 disabled:cursor-not-allowed`}
              title={isRecording ? "Stop recording" : "Start recording"}
            >
              <Mic className="w-5 h-5" />
            </button>
          )}

          <button
            type="submit"
            disabled={disabled || isUploading || (!input.trim() && !selectedFile)}
            className={`absolute bottom-3 right-3 p-1 transition-colors ${
              (input.trim() || selectedFile) && !disabled && !isUploading
                ? 'text-white hover:text-gray-300'
                : 'text-gray-600'
            } ${disabled ? 'cursor-not-allowed' : ''}`}
          >
            <Send className="w-5 h-5" />
          </button>
        </form>

        <div className="text-center mt-2">
          <p className="text-gray-500 text-xs">
            Solar Genie can make mistakes. Check important info.
          </p>
        </div>
      </div>
    </div>
  );
};
const styles = `
@keyframes audioWave {
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.5);
  }
}
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ChatInput;