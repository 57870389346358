import React, { useEffect, useRef } from 'react';
import { Image as ImageIcon, User, Bot, Volume2 } from 'lucide-react';

interface Message {
  content: string;
  isUser: boolean;
  isAudio?: boolean;
}

interface ChatHistoryProps {
  messages: Message[];
}

const MessageContent: React.FC<{ content: string }> = ({ content }) => {
  const urlRegex = /(https?:\/\/[^\s)]+)/g; 
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w\-]+)/;
  const parts = content?.split(urlRegex);

  return (
    <pre className="whitespace-pre-wrap font-sans text-xs sm:text-sm">
      { parts && parts.map((part, index) => {
        if (part.match(urlRegex)) {
          const youtubeMatch = part.match(youtubeRegex);
          if (youtubeMatch) {
            const videoId = youtubeMatch[1];
            return (
              <div key={index} className="my-2">
                <iframe
                  className="w-full max-w-md"
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            );
          } else if (/\.(jpeg|jpg|gif|png|svg|webp)$/i.test(part)) {
            // If the link is an image
            return (
              <a
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                key={index}
                src={part}
                alt="Link content"
                className="inline-block h-36 w-32 rounded shadow-md"
              />
              </a>
            );
          } else if (/\.pdf$/i.test(part)) {
            // If the link is a PDF
            // return (
            //   <div key={index} className="my-2 cursor-pointer relative" onClick={() => window.open(part, '_blank')}>
            //     <iframe
            //       src={part}
            //       width="100%"
            //       height="100%"
            //       className="border rounded shadow-md"
            //       title="PDF Viewer"
                  
            //     >
            //       <p>Your browser does not support PDF viewing. 
            //         <a href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            //           Click here to download the PDF
            //         </a>
            //       </p>
            //     </iframe>
            //   </div>
            // );

            return (
              <div 
                key={index} 
                className="my-2 cursor-pointer relative" 
                onClick={() => window.open(part, '_blank')}
                // style={{ height: '500px' }}
              >
                <iframe
                  src={part}
                  style={{ width: '100%', height: '100%' }}
                  className="border rounded shadow-md w-full pointer-events-none"
                  title="PDF Viewer"
                >
                  <p>Your browser does not support PDF viewing. 
                    <a href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                      Click here to download the PDF
                    </a>
                  </p>
                </iframe>
              </div>
            );

          } 
          
          
          else {
            // If it's any other link
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline mx-1"
              >
                {part}
              </a>
            );
          }
        }
        return part ? <span key={index}>{part}</span> : null;
      })}
    </pre>
  );
};

const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const lastProcessedMessageRef = useRef<string | null>(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (!lastMessage.isUser && lastMessage.isAudio && lastMessage.content !== lastProcessedMessageRef.current) {
        handleTextToSpeech(lastMessage.content);
        lastProcessedMessageRef.current = lastMessage.content;
      }
    }
  }, [messages]);

  const handleTextToSpeech = async (text: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/text-to-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error('Error in text-to-speech request:', error);
    }
  };

  return (
    <div className="flex flex-col p-1">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}
        >
          <div
            className={`flex max-w-[80%] ${
              message.isUser ? 'flex-row-reverse' : 'flex-row'
            }`}
          >
            <div
              className={`flex items-start space-x-2 ${
                message.isUser ? 'flex-row-reverse space-x-reverse' : 'flex-row'
              }`}
            >
              <div
                className={`min-w-8 min-h-8 rounded-full flex items-center justify-center ${
                  message.isUser ? 'bg-blue-100' : 'bg-gray-100'
                }`}
              >
                {message.isUser ? (
                  <User className="sm:min-w-5 sm:min-h-5 min-w-3.5 min-h-3.5 sm:max-w-5 sm:max-h-5 max-w-3.5 max-h-3.5 text-blue-600" />
                ) : (
                  <Bot className="sm:min-w-5 sm:min-h-5 min-w-3.5 min-h-3.5 sm:max-w-5 sm:max-h-5 max-w-3.5 max-h-3.5 text-gray-600" />
                )}
              </div>
              
              <div
                className={`sm:p-3 p-1 rounded-lg m-1 ${
                  message.isUser
                    ? 'bg-blue-600 text-white rounded-tr-none'
                    : 'bg-gray-100 text-gray-900 rounded-tl-none'
                }`}
              >
                <MessageContent content={message.content} />
                
                {
                // !message.isUser && message.content.trim() !== '' && (
                //   <button
                //     onClick={() => handleTextToSpeech(message.content)}
                //     className="text-gray-500 hover:text-gray-700 mt-1 p-1"
                //     title="Listen to message"
                //   >
                //     {/* <Volume2 className="w-4 h-4" /> */}
                //   </button>)
                  }
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={chatEndRef} className="h-4" />
    </div>
  );
};

export default ChatHistory;